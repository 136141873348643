import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
import{settingsCollectionDocs}from "@/utils/firebaseCollection"
const db = firebase.firestore();

//get Schools from firebase
export const getSchoolData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let schools = []
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SCHOOL)
                .orderBy('createdAt','desc')
                .onSnapshot((snapshot)=>{
                    schools = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            schools.push({id:doc.id,...doc.data()});
                        });
                        commit('setSchoolsArr',schools);
                        if(schools.length == 0){
                            resolve("No Data Found.");
                        }else{
                            // console.log("schools",schools)
                            resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.log("Error",error);
                });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

//get syllabus
export const getSyllabusData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let syllabus = []
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SYLLABUS)
                .orderBy('createdAt','desc')
                .onSnapshot((snapshot)=>{
                    syllabus = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            syllabus.push({id:doc.id,...doc.data()});
                        });
                        commit('setSyllabusArr',syllabus);
                        if(syllabus.length == 0){
                           resolve("No Data Found.");
                        }else{
                           resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.log("Error",error);
                });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

//create new school in firebase
export const createSchool = ({state},schoolObj)=>{
    return new Promise((resolve,reject)=>{
        try{
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SCHOOL).add(schoolObj)
                .then((res)=>{
                    db.collection(dbCollections.SETTINGS).doc("setting")
                      .collection(dbCollections.SCHOOL).doc(res.id).update({
                          id: res.id
                      }).then(()=>{
                          resolve("School Added !");
                      }).catch((err)=>{
                          reject(err)
                      })
                })
                .catch((err)=>{
                    reject(err)
                })
        }catch(error){
            // console.log("Error",error);
            reject(error);
        }
    })
};

//create new syllabus in firebase
export const createSyllabus = ({state},syllabusObj)=>{
    return new Promise((resolve,reject)=>{
        try{
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SYLLABUS).add(syllabusObj)
                .then((res)=>{
                    db.collection(dbCollections.SETTINGS).doc("setting")
                      .collection(dbCollections.SYLLABUS).doc(res.id).update({
                          id: res.id
                      }).then(()=>{
                          resolve("School Added !");
                      }).catch((err)=>{
                          reject(err)
                      })
                })
                .catch((err)=>{
                    reject(err)
                })
        }catch(error){
            // console.log("Error",error);
            reject(error);
        }
    })
};

// //get tutor verification status
// export const gettutorStatusData =({commit})=>{   
//     return new Promise((resolve,reject)=>{
//         try{
//             let userStatus = null;
//             db.collection(dbCollections.SETTINGS)
//             .doc("setting")
//             .collection(settingsCollectionDocs.STATUS).doc('tutorVerificationStatus')
//             .onSnapshot((snapshot)=>{
//                 userStatus = null
//                 userStatus = snapshot.data();  
//                 commit('gettutorStatusArr',userStatus);
//                 if(Object.keys(userStatus).length == 0){
//                     resolve("No Data Found.");
//                 }else{
//                     resolve("Records Found.");
//                 }                
//             },
//             (error)=>{
//                 console.log("Error",error);
//             });
//         }catch(error){
//             console.log("Error",error);
//             reject(error);
//         }
//     })
// };

// get user status
export const getStatusData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let userStatus = null;
            db.collection(dbCollections.SETTINGS)
            .doc("setting")
            .collection(settingsCollectionDocs.STATUS)
            .onSnapshot((snapshot)=>{
                userStatus = []
                if(snapshot.empty){
                    resolve("No Data Found")
                }else{
                    snapshot.forEach(doc=>{
                        if(doc.id == 'userStatus'){
                            userStatus= doc.data();
                        }
                    });
                    commit('setStatusArr',userStatus);
                    if(Object.keys(userStatus).length == 0){
                      resolve("No Data Found.");
                    }else{
                      resolve("Records Found.");
                    }
                }
            },
            (error)=>{
                console.log("Error",error);
            });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

//get tutor types (JYOTI)
export const getTutorTypes =({commit},datas)=>{
    return new Promise((resolve,reject)=>{
        try{
            console.log("MADE ARRAY")
            let tutorType = [];
            if(datas !== undefined && datas !== null && Object.keys(datas).length > 0){
              if(datas.tutorType && datas.tutorType !== null){
                let tutorTempType = datas.tutorType ? datas.tutorType : null 
                for(let i = 0; i < tutorTempType.length; i++){
                    let obj = {
                        id : i,
                        name : tutorTempType[i],  
                        variant : ''                      
                    }
                    tutorType.push(obj)
                } 
                resolve("Records Found.");
              }else{
                resolve("No Data Found."); 
              }
              commit('getTutorTypeArray',tutorType);
            }else{
             resolve("No Data Found") 
            }
            // db.collection(dbCollections.SETTINGS)
            // .doc("setting")
            // .collection(dbCollections.TUTORTYPES)
            // .onSnapshot((snapshot)=>{
            //         tutorType = [];
            //         if(snapshot.empty){
            //             resolve("No Data Found")
            //         }else{
            //             snapshot.forEach(doc=>{
            //                 tutorType.push(doc.data());
            //             });
            //             commit('getTutorTypeArray',tutorType);
            //             if(tutorType.length == 0){
            //                resolve("No Data Found.");
            //             }else{
            //                resolve("Records Found.");
            //             }
            //         }
            //     },
            //     (error)=>{
            //         console.log("Error",error);
            //     });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

//tutorTypeArrayFeild (KARAN)
export const getsettingArrayFeild =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let tutorType = {}
            db.collection(dbCollections.SETTINGS)
            .doc("setting")
            .onSnapshot((snapshot)=>{
                    tutorType = {};
                    if(snapshot.empty){
                        getTutorTypes({commit},tutorType)
                        resolve("No Data Found")                        
                    }else{
                        console.log("MAIN SETTTT")
                        // snapshot.forEach(doc=>{
                        //     tutorType.push(doc.data());
                        // });
                        tutorType = snapshot.data();
                        getTutorTypes({commit},tutorType)
                        commit('getsettingArray',tutorType);
                        resolve("Records Found.")
                        // if(settingArrayFeild.length == 0){
                        //    resolve("No Data Found.");
                        // }else{
                        //    resolve("Records Found.");
                        // }
                    }
                },
                (error)=>{
                    console.log("Error",error);
                });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};


//get teaching languages
export const getTeachingLanguages =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let languageArr = []
            db.collection(dbCollections.SETTINGS)
            .doc("setting")
            .collection(settingsCollectionDocs.TEACHINGLANGUAGES)
            .onSnapshot((snapshot)=>{
                    languageArr = [];
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            languageArr.push(doc.data());
                        });
                        commit('getTeachingLanguageArray',languageArr);
                        if(languageArr.length == 0){
                           resolve("No Data Found.");
                        }else{
                           resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.log("Error",error);
                });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

// get user status
export const getAllStatusData =({commit})=>{
    // debugger // eslint-disable-line
    return new Promise((resolve,reject)=>{
        try{
            let allStatus = null;
            db.collection(dbCollections.SETTINGS)
            .doc("status")
            .onSnapshot((doc)=>{
                allStatus = []
                if(doc.empty){
                    resolve("No Data Found")
                }else{
                    allStatus= doc.data();
                    console.log('allStatus',allStatus)
                    commit('setAllStatusArr',allStatus);
                    if(Object.keys(allStatus).length == 0){
                      resolve("No Data Found.");
                    }else{
                      resolve("Records Found.");
                    }
                }
            },
            (error)=>{
                console.log("Error",error);
            });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

//get emailstatus
// export const getALlEmailStatusData =({commit})=>{
//     return new Promise((resolve,reject)=>{
//         try{
//             let emailStatus = null;
//             db.collection(dbCollections.SETTINGS)
//             .doc("setting")
//             .collection(settingsCollectionDocs.STATUS)
//             .onSnapshot((snapshot)=>{
//                 emailStatus = []
//                 if(snapshot.empty){
//                     resolve("No Data Found")
//                 }else{
//                     snapshot.forEach(doc=>{
//                         if(doc.id == 'emailTemplateStatus'){
//                             emailStatus= doc.data();
//                         }
//                     });
//                     commit('setEmailStatusArr',emailStatus);
//                     if(Object.keys(emailStatus).length == 0){
//                       resolve("No Data Found.");
//                     }else{
//                       resolve("Records Found.");
//                     }
//                 }
//             },
//             (error)=>{
//                 console.log("Error",error);
//             });
//         }catch(error){
//             console.log("Error",error);
//             reject(error);
//         }
//     })
// };