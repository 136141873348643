import * as mutations from './settingsMutation'
import * as actions from './settingsAction'
export default{
    namespaced: true,
    state:{
        school:[],
        syllabus:[],
        tutorStatus:[],
        status:[],
        tutorTypes:[],
        languageArr:[],
        // notificationManagement:[]
        settingArrayFeild:{},
        allStatus:[],
        // emailStatus:[]
    },
    getters: {
        getSchools: state=>state.school,
        getSyllabus: state=>state.syllabus,
        gettutorStatus: state=>state.tutorStatus,
        getStatus: state=>state.status,
        getTutorTypes: state=>state.tutorTypes,
        getTeachingLanguages: state=>state.languageArr,
        // getNotifications: state=>state.notification,
        getsettingArrayFeild: state=> state.settingArrayFeild,
        getAllStatus: state=>state.allStatus,
        // getAllEmailStatus: state=>state.emailStatus
    },
    mutations,
    actions,
}