import * as mutations from './categoryMutation'
import * as actions from './categoryAction'
export default{
    namespaced: true,
    state:{
        category:[],
        subCategory:[],
    },
    // getters:{
    //     getCategories: state => state.category
    // },
    getters: {
        getCategories: state => state.category,
        getSubCategories: state=>state.subCategory
    },
    mutations,
    actions,
}