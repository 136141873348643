/**
 * 
 * This file is contains all of the firestore collection name which is used in our 
 * project. If your have required to create any new collection then you must be decalred here
 * and used as globally.
 * 
 */

/** ROOT COLLECTION NAME **/
// console.log("API",process.env.VUE_APP_FIREBASE_API_URL)
const dbCollections = {
    APIURL:process.env.VUE_APP_FIREBASE_API_URL,//Local
    // APIURL:'http://localhost:4000',//Local
    //APIURL:'https://api-erfolg.aliansoftware.net',//COMPANY
    // APIURL:'https://api.erfolgcounseling.com',//LIVE COUNSELING
    USERS : 'users',
    RESTAURANT_PRODUCTS : 'restaurantProducts',
    SETTINGS : 'settings',
    CATEGORY :  'category',
    SUBCATEGORY : 'subCategory',
    SESSIONS : 'sessions',
    REVIEWS : 'reviews',
    BANNERMANAGEMENTSTORAGE : 'bannerManagement/',
    BANNERMANAGEMENT : 'bannerManagement',
    REQUESTFORCATEGORY : 'requestForCategory',
    REQUESTFORSUBCATEGORY : 'requestForSubcategory',
    SCHOOL : 'school',
    EMAILMAILTEMPLATE:'emailTemplate',
    SYLLABUS : 'syllabus',
    BOOKING :'bookings',
    USERSTATUS :'userStatus',
    TUTORTYPES : 'tutorTypes',
    COMPLAINT: 'complaint',
    NOTIFICATIONMANAGEMENT:'notificationManagement',
    PLAN: 'plans',
    FAQ:'faq',
    TAC:"termsAndCondition",
    TESTTAC:"testTermsAndCondition",
    CREDITTRANSACTIONS:"creditTransactions",
    TUTORTRANSACTIONS:"tutorTransactions",
    CONNECTEDUSERS : "connectedUsers",
    SESSIONHISTORY:"sessionHistory"
}
const settingsCollectionDocs = {
    DOCUMENTCATEGORY: 'documentCategory',
    TEACHINGLANGUAGES:'teachingLanguages',
    STATUS: 'status',
}
module.exports = {
    dbCollections,
    settingsCollectionDocs
};