import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

//get sessionData from firebase
export const getSessionsData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let sessions = []
            db.collection(dbCollections.SESSIONS)
                .onSnapshot(async(snapshot)=>{
                    sessions = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            sessions.push({id:doc.id,...doc.data()});
                        });
                        // console.log("before",new Date())
                        // try{
                        //     for(var i=0;i<sessions.length;i++){
                        //         if(sessions[i].category){
                        //             await sessions[i].category.get().then((res)=>{
                        //                 if(res){
                        //                     sessions[i].category = res.data();
                        //                 }
                        //             })
                        //             // state.dispatch("getValueByRef",sessions[i].category).then((res)=>{
                        //             //     console.log("res",res);
                        //             //     sessions[i].category = state.value
                        //             // }).catch((error)=>{
                        //             //     console.log(error)
                        //             // })
                        //         }
                        //         if(sessions[i].subCategory){
                        //             await sessions[i].subCategory.get().then((res)=>{
                        //                 if(res){
                        //                     sessions[i].subCategory = res.data();
                        //                 }
                        //             })
                        //         }
                        //         if(sessions[i].tutor){
                        //             await sessions[i].tutor.get().then((res)=>{
                        //                 sessions[i].tutor = res.data();
                        //             })
                        //         }
                        //     }
                        // // console.log("after",new Date())
                        // // console.log("sessions",sessions)
                        // }catch(error){
                        //     console.log("error",error)
                        // }
                        // console.log("sessions",sessions)
                        commit('setSessionsArr',sessions);
                        if(sessions.length == 0){
                            resolve("No Data Found.");
                        }else{
                            resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.log("Error",error);
                });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

// get value by ref
// export const getValueByRef = ({commit},refOfValue)=>{
//     return new Promise(async (resolve,reject)=>{
//         try{
//             let value
//             await refOfValue.get().then((res)=>{
//                 value = res.data();
//             })
//             if(value){
//                 resolve("data Found");
//                 commit("setValue",value)
//             }
//         }catch(error){
//             console.log("Error" , error);
//             reject(error);
//         }
//     })
// };