import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
    namespaced: true,
    state: {
        booking: []
    },
    getters: {
        getBooking: state => state.booking
    },
    mutations: {
        setBooking(state, payload) {
            state.booking = payload;
        },
        spliceBooking(state, payload) {
            // debugger // eslint-disable-line
            state.booking.splice(payload,1)
        },
    },
    actions: {
        getBookingData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let review = [];
                    db.collection(dbCollections.BOOKING).get().then(snapshot => {
                        //reset users array on snapshot
                        review = [];
                        //if Empty return empty
                        if (snapshot.empty) {
                            resolve("No Data Found.");
                        }
                        else {
                            snapshot.forEach(doc => {
                                if (doc.data() && doc.data().status != 3)
                                    review.push(doc.data());
                            });
                            state.commit("setBooking", review);

                            if (review.length == 0) {
                                resolve("No Data Found.");
                            }
                            else {
                                resolve("Records Found.");
                            };
                        }

                        // console.log("IS Local?", snapshot.metadata.fromCache,snapshot.size);
                    },
                        (error) => {
                            console.log("Error", error);
                        });
                    // .catch((error)=>{
                    //   reject(error);
                    // });
                }
                catch (error) {
                    console.log("Error", error);
                    reject(error);
                };
            });
        }
    },
}
