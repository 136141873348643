let data = [];
data = [
  //Project Routyes
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Dashboard/dashboardList.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        // {
        //   text: 'UI',
        // },
        {
          text: 'Dashboard',
          active:true
        },
      ],
    },
  },
  {
    path: '/zoom',
    name: 'sessionDetail',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Dashboard/sessionDetail.vue'),
    meta: {
      pageTitle: 'sessionDetail',
      breadcrumb: [
        // {
        //   text: 'UI',
        // },
        {
          text: 'sessionDetail',
          active:true
        },
      ],
    },
  },
  // {
  //   path: '/Meetingend',
  //   name: 'Meetingend',
  //   component: () => import(/* webpackChunkName: "[request]" */'@/views/Dashboard/meetingEndPage.vue'),
  //   meta: {
  //     pageTitle: 'Meetingend',
  //     breadcrumb: [
  //       // {
  //       //   text: 'UI',
  //       // },
  //       {
  //         text: 'Meetingend',
  //         active:true
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/demoapi',
  //   name: 'demoapi',
  //   component: () => import(/* webpackChunkName: "[request]" */'@/views/Dashboard/demoApi.vue'),
  //   meta: {
  //     pageTitle: 'demoapi',
  //     breadcrumb: [
  //       // {
  //       //   text: 'UI',
  //       // },
  //       {
  //         text: 'demoapi',
  //         active:true
  //       },
  //     ],
  //   },
  // },
  
]
export default data
