import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import settings from './settings'
import sesionManagement from './sessionManagement'
import currentOnline from './currentOnline'
import upComingSession from './upComingSession'
import booking from './booking'
import userManagement from './userManagement'
import categoryManagement from './categoryManagement'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    settings,
    sesionManagement,
    currentOnline,
    upComingSession,
    booking,
    categoryManagement,
    userManagement
  },
  getters:{
    getRoutes: state=> state
  },
  strict: process.env.DEV,
})
