import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
    namespaced: true,
    state: {
        currentOnline: []
    },
    getters: {
        getOnlineCurrent: state => state.currentOnline
    },
    mutations: {
        setOnlineCurrentArr(state, payload) {
            state.currentOnline = payload;
        },
    },
    actions: {
        getOnlineCurrentData(state) {
            // debugger // eslint-disable-line
            return new Promise((resolve, reject) => {
                try {
                    let currentOnline = [];

                    db.collection(dbCollections.BOOKING)
                    .where("startTime", ">", new Date(new Date().getTime() - 180 * 60 * 1000))
                    .where("startTime", "<", new Date())
                    .where("status", "in", [1,5])
                    .onSnapshot(snapshot => {
                        //reset users array on snapshot
                        currentOnline = [];
                        //if Empty return empty
                        if (snapshot.empty) {
                            resolve("No Data Found.");
                        }
                        else {
                            snapshot.forEach(doc => {
                                if (doc.data()){
                                    currentOnline.push(doc.data());
                                }
                            });
                            state.commit("setOnlineCurrentArr", currentOnline);
                            console.log("currentOnline?", currentOnline);

                            if (currentOnline.length == 0) {
                                resolve("No Data Found.");
                            }
                            else {
                                resolve("Records Found.");
                            };
                        }

                        // console.log("IS Local?", snapshot.metadata.fromCache,snapshot.size);
                    },
                        (error) => {
                            console.log("Error", error);
                        });
                    // .catch((error)=>{
                    //   reject(error);
                    // });
                }
                catch (error) {
                    console.log("Error", error);
                    reject(error);
                };
            });
        }
    },
}
