<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

import { mapActions,mapGetters } from 'vuex';
import Vue from 'vue';
export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  data(){
    return{
      event:['click','mousemove','mousedown','scroll','keypress','load'],
      logoutTimer:null
    }
  },
  mounted(){
    try{
      this.event.forEach(function (eve){
        window.addEventListener(eve,this.resetTimer)
      },this)
      this.setTimers();
    }catch(error){
      console.log(error)
    }
  },
  created()
  {
    //USE FOR MULTI PLACES
    Vue.prototype.$DetailPage_User_IsLearner = true;

    console.log('env mode',process.env.NODE_ENV);
    this.$root.$on('showToastMessage',(title,variant)=>{
      this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: title,
              variant,
              icon: 'InfoIcon'
            },
        })
    });
   // //FOR ALL STATUS
    let allStatusFound = this.getAllStatusList.length != 0;
    if(!allStatusFound){
      this.getAllStatus().then(res=>{
        console.log("for status",res)
      }).catch(error=>{
        console.log("error",error)
      })
    }
    
  //For Setting
    let settingMangFound = Object.keys(this.getsettingList).length != 0;
    if (!settingMangFound) {
       this.getsettingArrayFeild().then((res)=>{
         console.log("setting manage",res)
       }).catch(error=>{
        console.log("error",error)
      })
    }
  },
  methods: {
    ...mapActions({
        'getSchools' : 'settings/getSchoolData',
        'getSyllabus' : 'settings/getSyllabusData',
        'getTutorTypes' : 'settings/getTutorTypes',
        'getTeachingLanguages' : 'settings/getTeachingLanguages',
        'getAllStatus' : 'settings/getAllStatusData',
        'getsettingArrayFeild' : 'settings/getsettingArrayFeild',
      }),
      setTimers(){
        try{
          this.logoutTimer = setTimeout(this.warningMessage, 3600 * 1000)
        }catch(error){
          console.log(error)
        }
      },
      warningMessage(){
        try{
          if(localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== undefined && JSON.parse(localStorage.getItem('currentUser')) !== null){
          this.$root.$emit('logoutUser')
          localStorage.removeItem('jwtToken');
          }
        }catch(error){
          console.log(error)
        }
      },
      resetTimer(){
        try{
          clearTimeout(this.logoutTimer)
          this.setTimers();
        }catch(error){
          console.log(error)
        }
      }
 },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters({
        getSchoolList: 'settings/getSchools',
        getSyllabusList: 'settings/getSyllabus',
        gettutorTypesList:'settings/getTutorTypes', 
        getTeachLanguageList:'settings/getTeachingLanguages',   
        getAllStatusList:'settings/getAllStatus' ,
        getsettingList : 'settings/getsettingArrayFeild',
      }), // lists from store.
    
    layout() {
      // console.log('this.$route',this.$route)
      if (this.$route.meta.layout === 'full') 
      return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>