import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
    namespaced: true,
    state: {
        upComingSession: []
    },
    getters: {
        getupComingSession: state => state.upComingSession
    },
    mutations: {
        setupComingSessionArray(state, payload) {
            state.upComingSession = payload;
        },
        testMutation(state,payload){
            state.upComingSession.splice(payload,1)
        }
    },
    actions: {
        getupComingSessionData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let upComingSession = [];

                    db.collection(dbCollections.BOOKING)
                    .where("startTime", ">", new Date())
                    // .where("startTime", "<" , new Date(new Date().getTime() + (180 * 60 * 1000 * 8)))
                    .where("status", "in", [1,5])
                    .onSnapshot(snapshot => {
             
                        upComingSession = [];
            
                        if (snapshot.empty) {
                            resolve("No Data Found.");
                        }
                        else {
                            snapshot.forEach(doc => {
                                if (doc.data()){
                                    upComingSession.push(doc.data());
                                }
                            });
                            // console.log('shraddha',upComingSession);
                            state.commit("setupComingSessionArray", upComingSession);

                            if (upComingSession.length == 0) {
                                resolve("No Data Found.");
                            }
                            else {
                                resolve("Records Found.");
                            };
                        }
                    },
                        (error) => {
                            console.log("Error", error);
                        });
                }
                catch (error) {
                    console.log("Error", error);
                    reject(error);
                };
            });
        }
    },
}