export const setSchoolsArr = (state,schoolArr)=>{
    state.school = schoolArr
};

export const setSyllabusArr = (state,syllabusArr)=>{
    state.syllabus = syllabusArr
};
export const gettutorStatusArr = (state,userStatusArr)=>{
    state.tutorStatus = userStatusArr
};
export const setStatusArr = (state,statusArr)=>{
    state.status = statusArr
};
export const getTutorTypeArray= (state,tutorTypeArr)=>{
    state.tutorTypes = tutorTypeArr
};

export const getTeachingLanguageArray = (state,teach_lang)=>{
    state.languageArr = teach_lang
};

export const getsettingArray = (state,settingArrayFeild)=>{
    state.settingArrayFeild = settingArrayFeild
};

export const setAllStatusArr = (state,allStatusArr)=>{
    state.allStatus = allStatusArr
};
// export const setEmailStatusArr = (state,statusEmailArr)=>{
//     state.emailStatus = statusEmailArr
// };