import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

//get categories from firebase
export const getCategoriesData =({commit})=>{
    // debugger // eslint-disable-line
    return new Promise((resolve,reject)=>{
        try{
            let categories = []
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.CATEGORY)
                .orderBy('createdAt','desc')
                .onSnapshot((snapshot)=>{
                    categories = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            categories.push({id:doc.id,...doc.data()});
                        });
                        console.log('categories',categories)
                        commit('setCategoriesArr',categories);
                        if(categories.length == 0){
                            resolve("No Data Found.");
                        }else{
                            // console.log("cat",categories)
                            resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.log("Error",error);
                });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

//get subCategories from firebase
export const getSubCategoriesData =({commit})=>{
    // debugger // eslint-disable-line
    return new Promise((resolve,reject)=>{
        try{
            let subcategories = []
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SUBCATEGORY)
                .orderBy('createdAt','desc')
                .onSnapshot((snapshot)=>{
                    subcategories = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            subcategories.push({id:doc.id,...doc.data()});
                        });
                        commit('setSubCategoriesArr',subcategories);
                        if(subcategories.length == 0){
                            resolve("No Data Found.");
                        }else{
                            // console.log("cat",categories)
                            resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.log("Error",error);
                });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

//create new category in firebase
export const createCategory = ({state},categoryObj)=>{
    return new Promise((resolve,reject)=>{
        try{
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.CATEGORY).add(categoryObj)
                .then(()=>{
                    resolve("Category Added !");
                });
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};

//create subCategory in firebase
export const createSubCategory = ({state},subcategoryObj)=>{
    return new Promise((resolve,reject)=>{
        try{
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SUBCATEGORY).add(subcategoryObj)
                .then((res)=>{
                    db.collection(dbCollections.SETTINGS).doc("setting").collection(dbCollections.SUBCATEGORY).doc(res.id).update({id : res.id}).then(()=>{
                        resolve("SubCategory Added !");
                    }).catch((error)=>{
                        reject(error);
                    })
                }).catch((error)=>{
                    reject(error);
                })
        }catch(error){
            console.log("Error",error);
            reject(error);
        }
    })
};
